import React from 'react'
import { Container, Box, Heading, Text, ul, li, Flex } from 'theme-ui'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const Privacy = props => {
  const { allBlockContent } = props.data || {}
  const content = normalizeBlockContentNodes(allBlockContent?.nodes || [])

  const modalContent = content['contact'] || {
    identifier: 'contact',
    tabs: []
  }

  const personalInfoItems = [
    'Name',
    'Email address',
    'Phone number',
    'Company name',
    'Job title',
    'Payment details (if applicable)',
    'Any other information you voluntarily provide through forms, emails, or account creation'
  ]

  const automaticInfoItems = [
    'IP address',
    'Browser type and version',
    'Operating system',
    'Referring website',
    'Pages visited and time spent on the Website',
    'Device identifiers',
    'Location data (if enabled)'
  ]

  const cookiesItems = [
    'Your preferences and interests',
    'Navigation patterns on our Website'
  ]

  return (
    <Layout {...props}>
      <Seo title='Privacy Policy' />
       {/* Modals */}
      <ModalWithTabs content={modalContent} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Container variant="wide" sx={{ py: 4 }}>
        <Box sx={{ maxWidth: '800px', mx: 'auto' }}>
          <Heading as="h1" sx={{ textAlign: 'center', mb: 4 }}>Privacy Policy</Heading>
          
          <Box sx={{ mb: 4 }}>
            <Text sx={{ fontSize: 1, color: 'gray' }}>
              Effective Date: 20.11.2024<br />
              Last Updated: 20.11.2024
            </Text>
          </Box>

          <Text sx={{ mb: 4 }}>
            Welcome to Be&Reach (the "Website"). At Be&Reach, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding that information. By accessing or using our Website, you agree to the practices described in this Privacy Policy.
          </Text>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>1. Information We Collect</Heading>
            <Text>We collect the following types of information:</Text>
            
            <Heading as="h3" sx={{ mt: 3, mb: 2 }}>1.1 Personal Information You Provide</Heading>
            <ul sx={{ pl: 4, mb: 3 }}>
              {personalInfoItems.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>

            <Heading as="h3" sx={{ mt: 3, mb: 2 }}>1.2 Automatically Collected Information</Heading>
            <ul sx={{ pl: 4, mb: 3 }}>
              {automaticInfoItems.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>

            <Heading as="h3" sx={{ mt: 3, mb: 2 }}>1.3 Cookies and Tracking Technologies</Heading>
            <Text sx={{ mb: 2 }}>We use cookies, web beacons, and similar technologies to enhance your experience on our Website. These may collect:</Text>
            <ul sx={{ pl: 4, mb: 3 }}>
              {cookiesItems.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>2. How We Use Your Information</Heading>
            <Text>We use the information collected for the following purposes:</Text>
            <ul sx={{ pl: 4, mb: 3 }}>
              <li>To provide and improve our services.</li>
              <li>To process transactions and communicate with you regarding your orders or inquiries.</li>
              <li>To personalize your experience on our Website.</li>
              <li>To send promotional emails, newsletters, or special offers (with your consent).</li>
              <li>To ensure security and prevent fraudulent activities.</li>
              <li>To comply with legal obligations and enforce our terms and conditions.</li>
            </ul>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>3. How We Share Your Information</Heading>
            <Text>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following scenarios:</Text>
            <ul sx={{ pl: 4, mb: 3 }}>
              <li><strong>Service Providers:</strong> With third-party vendors or partners who help us operate the Website or provide services on our behalf.</li>
              <li><strong>Legal Obligations:</strong> To comply with legal requirements, such as responding to subpoenas or court orders.</li>
              <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.</li>
            </ul>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>4. Cookies and Tracking Technologies</Heading>
            <Text>We use cookies and similar technologies to improve your experience on our Website. You can manage your cookie preferences through your browser settings. Please note that disabling cookies may affect the functionality of our Website.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>5. Third-Party Links</Heading>
            <Text>Our Website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review their privacy policies before providing any personal information.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>6. Data Retention</Heading>
            <Text>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>7. Your Rights</Heading>
            <Text>You have the following rights regarding your personal information:</Text>
            <ul sx={{ pl: 4, mb: 3 }}>
              <li><strong>Access:</strong> Request a copy of the personal data we hold about you.</li>
              <li><strong>Correction:</strong> Request corrections to your personal data if it is inaccurate or incomplete.</li>
              <li><strong>Deletion:</strong> Request the deletion of your personal data.</li>
              <li><strong>Restriction:</strong> Request restrictions on the processing of your personal data.</li>
              <li><strong>Portability:</strong> Request a transfer of your personal data to another service provider.</li>
              <li><strong>Withdraw Consent:</strong> Withdraw consent for the processing of your data where consent was provided.</li>
            </ul>
            <Text>To exercise these rights, please contact us at <a href="mailto:info@beandreach.com">info@beandreach.com</a></Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>8. Security</Heading>
            <Text>We implement industry-standard security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>9. Children's Privacy</Heading>
            <Text>Our Website is not intended for children under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have collected such information, we will take steps to delete it.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>10. International Users</Heading>
            <Text>If you are accessing our Website from outside the UAE, please note that your information may be transferred and processed in the UAE, where privacy laws may differ from your jurisdiction.</Text>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>11. Changes to This Privacy Policy</Heading>
            <Text>We reserve the right to update this Privacy Policy at any time. Changes will be effective upon posting to this page. We encourage you to review this Privacy Policy periodically.</Text>
          </Box>

          {/* Example of how to style contact section */}
          <Box sx={{ mb: 4 }}>
            <Heading as="h2" sx={{ mb: 3 }}>12. Contact Us</Heading>
            <Text>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</Text>
            <Flex sx={{ flexDirection: 'column', alignItems: 'start', mt: 2 }}>
              <Text>Email: <a href="mailto:info@beandreach.com">info@beandreach.com</a></Text>
              <Text>Address: Shams Media City, Sharjah, UAE</Text>
            </Flex>
          </Box>

          <Text sx={{ fontStyle: 'italic', textAlign: 'center' }}>
            By using our Website, you acknowledge that you have read and understood this Privacy Policy. Thank you for trusting Be&Reach.
          </Text>
        </Box>
      </Container>

      <Divider space='5' />
      <Footer content={content['footer']} />
     </Layout>
  )
}

export const query = graphql`
  query innerpageAboutUsBlockContentPrivacy {
    allBlockContent(
      filter: { page: { in: ["site/about-us", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Privacy
